* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Reem Kufi", sans-serif !important;
}
img {
  float: left;
  /* background: red; */
  max-width: 50%;
  height: auto;
}
.imgLawyer {
  width: 100px;
  height: 100px;
}
.divLawyer {
  /* float:left; */
  /* background: yellow; */
  margin: 10px;
}
.droit-travail {
  /* float:left; */
}
.listStyleType {
  list-style-type: none;
}
.App {
  min-height: 100vh;
  width: 85vw;
  max-width: 1900px;
  margin: 0rem auto;
  background-color: pink;
  margin-left: 1em;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}

